<template>
  <div class="mainNav">
    <router-link to="/"><img src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png" class="logo"></router-link>
  </div>
</template>

<script>
  export default {
    name: "src-pages-header-shared-simple"
  }
</script>

<style scoped>
  .mainNav{
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    height:64px;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .logo{
    margin:10px;
    height: 29px;
    width: 120px;
  }

</style>
